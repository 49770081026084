import React from 'react';
import styled from 'styled-components';
import {
  Color,
  fullColorBackground,
  height,
  paddingTopBottom,
  width,
  maxWidth,
  flex,
  typographyByBreakpoint,
  h1Mobile,
  h2Mobile,
  h2Desktop,
  bodyDefault,
  displayNone,
  marginTop,
  position,
} from '../../styles';
import { DefaultCtaButton } from '../Gui/Button';

const Wrapper = styled.section`
  position: relative;
  ${paddingTopBottom([72, 72], [72, 72], [100, 100])};
  ${fullColorBackground(Color.SEAFOAM_GREEN)}; /* Seafoam Green Banner Background */
`;

const Vector = styled.div`
  position: absolute;
  bottom: 48px;
  ${position('left', -210, -56, -188)};
  ${width('39.1rem', '39.1rem', '82.7rem')};
  ${height('39.1rem', '39.1rem', '82.7rem')};
  border-radius: 50%;
  background: linear-gradient(136.25deg, #B5CDC5, #748187); /* Green Circle In Banner */
  opacity: 0.5;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  overflow: hidden;
  z-index: -1;
`;

const Content = styled.div`
  ${maxWidth('31.1rem', '52.8rem', '85rem')};
  margin-inline: auto;
  ${flex('center', 'center', 'column')};

  h2,
  p {
    color: ${Color.OFF_WHITE};
    text-align: center;
  }

  h2 {
    ${typographyByBreakpoint(h1Mobile, h2Mobile, h2Desktop)};
  }

  p {
    ${bodyDefault};
    margin-top: 8px;
  }

  .mobile-heading {
    ${displayNone('block', false, true, true)};
  }
  .desktop-heading {
    ${displayNone('block', true, false, false)};
  }

  .button-wrapper {
    ${marginTop(32, 32, 48)};
    padding: 16px 30px;
    text-decoration: none;
    outline: 1px solid transparent;
    z-index: 1;
  }
`;

const JoinSection = () => (
  <Wrapper>
    <Vector />
    <Content>
      <div className={'mobile-heading'}>
        <h2>Join us</h2>
        <p>Create a meaningful change for communities everywhere</p>
      </div>
      <div className={'desktop-heading'}>
        <h2>Join us and create a meaningful change for communities everywhere</h2>
      </div>
      <DefaultCtaButton className={'shared-join-cta'} url={'https://share.hsforms.com/12aF6o7kPSQSFCJC-WobAlwcvcyq'} theme={'SOLID_GREEN'}>
        Ohio Pilot
      </DefaultCtaButton>
    </Content>
  </Wrapper>
);

export default JoinSection;
