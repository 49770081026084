import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  bodyDefault,
  Color,
  displayNone,
  flex,
  h1Desktop,
  h1Mobile,
  h2Desktop,
  h2Mobile,
  marginBottom,
  marginTop,
  maxWidth,
  typographyByBreakpoint,
  Width,
  width,
} from '../../styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Carousel } from '../Gui/Carousel';
import { getImageFromAssets, owlSelector } from '../../utils';

const Wrapper = styled.section`
  ${maxWidth('32.7rem', '68.8rem', 'unset')};
  margin-inline: auto;

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    ${flex('space-between', 'flex-start', 'row-reverse')};
  }

  @media print, screen and (min-width: ${Width.BREAK_XL}px) {
    ${flex('flex-end', 'flex-start', 'row-reverse')};
  }
`;

const Blurb = styled.div`
  position: relative;

  h2,
  p {
    position: relative;
    z-index: 2;
    color: ${Color.BLACK};
  }

  h2 {
    ${typographyByBreakpoint(h1Mobile, h1Mobile, h1Desktop)};
  }

  p {
    ${bodyDefault};
    margin-top: 8px;
  }
`;

const ContentContainer = styled.div`
  flex: 0 0 auto;
  ${width('unset', '32.7rem', '60.9rem')};
  ${marginTop(56, 48)};
  ${marginBottom(88)};

  > div:last-of-type {
    margin-top: 56px;

    @media print, screen and (min-width: ${Width.BREAK_MD}px) {
      display: none;
    }
  }
`;

const ImageContainer = styled.div`
  .mobile-img {
    ${displayNone('block', false, true, true)};
  }

  .tablet-img {
    ${displayNone('block', true, false, true)};
    position: relative;
    left: 40px;
    width: 33.1rem;
  }

  .desktop-img {
    ${displayNone('block', true, true, false)};
    position: relative;
    left: 80px;
    width: 66.2rem;
    height: 100vh;
  }

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    position: sticky;
    top: 0;
  }
`;

const CarouselWrapper = styled.div<{ $mobileCarouselHeight: number }>`
  position: relative;
  width: 100%;
  height: ${({ $mobileCarouselHeight }) => `${$mobileCarouselHeight / 10}rem`};
  margin-top: 24px;
  margin-bottom: 80px;

  ::after {
    content: '';
    display: block;
    height: ${({ $mobileCarouselHeight }) => `${$mobileCarouselHeight / 10}rem`};
    position: absolute;
    right: calc((100vw - 100%) / -2);
    background: linear-gradient(90deg, rgba(249, 249, 249, 0) 52%, #f9f9f9 98.43%);
    left: 0;
    pointer-events: none;
  }
`;

const opacityZeroPositions = [-3, -2, -1, 3];

const SlideWrapper = styled.div<{ $positionX: number }>`
  position: absolute;
  transition: transform 300ms ease, opacity 300ms ease 300ms;

  transform: ${({ $positionX }) => `translateX(calc(${$positionX * 100}% + ${$positionX * 32}px))`};
  opacity: ${({ $positionX }) => (opacityZeroPositions.includes($positionX) ? 0 : 1)};
  width: 23.3rem;

  h3 {
    ${h2Mobile};
  }

  p {
    ${bodyDefault};
    margin-top: 8px;
  }
`;

const SlideButtonsContainer = styled.div`
  align-self: flex-start;

  > * + * {
    margin-left: 12px;
  }
`;

const SlideDotStyles = css<{ $active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  border: 1px solid ${({ $active }) => ($active ? Color.BLACK : Color.PURPLE_GRAY)};
  background-color: ${({ $active }) => ($active ? Color.BLACK : 'none')};
`;

const SlideButton = styled.button`
  ${SlideDotStyles};
`;

const ScrollContentContainer = styled.div<{ $active: boolean }>`
  transition: opacity 300ms ease;
  opacity: ${({ $active }) => ($active ? 1 : 0.3)};

  h3,
  p {
    color: ${Color.BLACK};
  }

  h3 {
    ${typographyByBreakpoint(h2Mobile, h2Mobile, h2Desktop)};
  }

  p {
    ${bodyDefault};
    margin-top: 8px;
  }

  .button-wrapper {
    margin-top: 32px;
  }
`;

const ContentSection = styled.div`
  ${width('unset', '28.7rem', '46.8rem')};
  margin-left: auto;

  > ${ScrollContentContainer} + ${ScrollContentContainer} {
    ${marginTop(40, 40, 72)};
  }

  @media print, screen and (max-width: ${Width.BREAK_MD - 1}px) {
    display: none;
  }
`;

const ScrollingSectionContainer = styled.div`
  ${flex('flex-start', 'flex-start')};
  ${displayNone('flex', true, false, false)};
  ${marginTop(56, 56, 80)};
`;

const ActiveIndicatorContainer = styled.div<{ ref: any; SlideDotStyles?: any }>`
  display: inline-block;
  width: 8px;
  ${owlSelector('top', 12)};
  position: sticky;
  top: calc(50vh - 45px);

  > div {
    ${SlideDotStyles};
  }
`;

const ActiveIndicator = ({
  slideData,
  activeIndex,
  activeIndicatorRef,
}: {
  slideData: any[];
  activeIndex: number;
  activeIndicatorRef: RefObject<any>;
}) => {
  return (
    <ActiveIndicatorContainer ref={activeIndicatorRef}>
      {slideData.map(({ heading, body }, index) => {
        return <SlideButton key={`SlideButton-${index}`} $active={activeIndex === index} />;
      })}
    </ActiveIndicatorContainer>
  );
};

const ScrollContent = ({
  heading,
  body,
  active,
  activeIndicatorRef,
  setActiveIndex,
  index,
}: {
  heading: string;
  body: string;
  active: boolean;
  activeIndicatorRef: RefObject<any>;
  setActiveIndex: Function;
  index: number;
}) => {
  const scrollContentRef = useRef(null);

  const handleActiveSection = useCallback(() => {
    const scrollContentRect = scrollContentRef.current?.getBoundingClientRect();
    const activeIndicatorRect = activeIndicatorRef.current?.getBoundingClientRect();
    const activeIndicatorBottom = activeIndicatorRect?.bottom;
    const scrollContentRectTop = scrollContentRect?.top;
    const scrollContentRectBottom = scrollContentRect?.bottom;

    if (activeIndicatorBottom > scrollContentRectTop && activeIndicatorBottom < scrollContentRectBottom) {
      setActiveIndex(index);
    }
  }, [scrollContentRef, activeIndicatorRef, setActiveIndex]);

  useEffect(() => {
    window.addEventListener('scroll', handleActiveSection);

    return () => {
      window.removeEventListener('scroll', handleActiveSection);
    };
  }, [scrollContentRef, activeIndicatorRef]);

  return (
    <ScrollContentContainer ref={scrollContentRef} $active={active}>
      <h3>{heading}</h3>
      <p>{body}</p>
    </ScrollContentContainer>
  );
};

const ScrollingSection = ({ slideData }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const activeIndicatorRef = useRef(null);

  return (
    <ScrollingSectionContainer>
      <ActiveIndicator activeIndicatorRef={activeIndicatorRef} slideData={slideData} activeIndex={activeIndex} />
      <ContentSection>
        {slideData.map(({ heading, body }, index) => {
          return (
            <ScrollContent
              key={`ScrollContent-${index}`}
              heading={heading}
              body={body}
              active={activeIndex === index}
              activeIndicatorRef={activeIndicatorRef}
              setActiveIndex={setActiveIndex}
              index={index}
            />
          );
        })}
      </ContentSection>
    </ScrollingSectionContainer>
  );
};

const StickyScrollSection = ({
  slideData,
  assets,
  imageNames,
  mobileCarouselHeight,
  className,
  children,
}: {
  slideData: Object[];
  assets: any[];
  imageNames: { mobile: string; tablet: string; desktop: string };
  mobileCarouselHeight: number;
  className?: string;
  children: any;
}) => {
  return (
    <Wrapper className={className}>
      <ImageContainer>
        <GatsbyImage className={'mobile-img'} image={getImage(getImageFromAssets(assets, imageNames.mobile))} alt={''} />
        <GatsbyImage className={'tablet-img'} image={getImage(getImageFromAssets(assets, imageNames.tablet))} alt={''} />
        <GatsbyImage className={'desktop-img'} image={getImage(getImageFromAssets(assets, imageNames.desktop))} alt={''} />
      </ImageContainer>
      <ContentContainer className={'content-container'}>
        <Blurb>
          {children}
          <ScrollingSection slideData={slideData} />
        </Blurb>

        <Carousel label={'Carousel'} data={slideData}>
          {({ content, currentIndex, slideContent, slidePositionX }: any) => (
            <>
              <SlideButtonsContainer>
                {slideData.map((slide, index) => (
                  <SlideButton key={`SlideButton-${index}`} $active={content[currentIndex]?.realIndex === index} />
                ))}
              </SlideButtonsContainer>
              <CarouselWrapper $mobileCarouselHeight={mobileCarouselHeight}>
                {slideContent.map(({ heading, body }, index) => (
                  <SlideWrapper key={`SlideWrapper-${index}`} $positionX={slidePositionX[index]}>
                    <h3>{heading}</h3>
                    <p>{body}</p>
                  </SlideWrapper>
                ))}
              </CarouselWrapper>
            </>
          )}
        </Carousel>
      </ContentContainer>
    </Wrapper>
  );
};

export default StickyScrollSection;
