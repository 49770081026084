import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Helmet } from 'react-helmet';
import WebsiteDefaultPage from '../../components/Page/Base';
import { Color, flex, marginBottom, marginTop, maxWidth, minHeight, Width } from '../../styles';
import { CompanyLogoIcon } from '../Svg';

const enlarge = keyframes`
  0% { transform: scale(2) rotate(0deg); opacity: 1 }
  15% { transform: scale(2) rotate(45deg); opacity: 1 }
  100% { transform: scale(2) rotate(-360deg); opacity: 0.7 }
`;

const Wrapper = styled.section`
  ${marginTop(80, 120, 160)};
  ${marginBottom(80, 120, 160)};

  .logo {
    height: 100vh;
    ${flex()};

    svg {
      margin-top: -140px;
      animation-name: ${enlarge};
      animation-duration: 1000ms;
      animation-timing-function: ease;
    }
  }

  .hbspt-form {
    ${maxWidth('32.7rem', '57rem')};
    margin-inline: auto;

    // have to override iframe height here because form would load in without the logo at the top, and the iframe would take
    // the height of the form without it, then the logo would load in afterward, pushing the "submit" button out of the container.
    iframe {
      height: 150.1rem !important;

      @media print, screen and (min-width: ${Width.BREAK_MD}px) {
        height: 120.6rem !important;
      }
    }
  }
`;

const HubspotFormSection = ({ formId }: { formId: string }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const loadForm = setTimeout(() => {
      const wrapper = document.getElementById('wrapper');
      const scriptContent = document.createElement('script');
      scriptContent.innerHTML = `hbspt.forms.create({region: "na1", portalId: "21618530", formId: "${formId}"});`;
      wrapper.appendChild(scriptContent);
      setLoading(false);
    }, 1000);

    return () => clearTimeout(loadForm);
  }, []);

  return (
    <>
      <Helmet>
        <script charSet="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js?pre=1" />
      </Helmet>
      <WebsiteDefaultPage bodyBackground={Color.OFF_WHITE}>
        <Wrapper id={'wrapper'}>{loading ? <div className={'logo'}>{CompanyLogoIcon}</div> : null}</Wrapper>
      </WebsiteDefaultPage>
    </>
  );
};

export default HubspotFormSection;
